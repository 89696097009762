/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './payment-style'
import Title from '../title/title-view'
import Info from '../info/info-view'
import {
  AddressManager,
  Button,
  Checkbox,
  InputRadio,
  PaymentProviderTokenDeleteModal,
} from '../../../../ui'
import combineClassNames from '../../../../helpers/combineClassNames'

const PaymentView = ({
  active,
  availablePaymentProviders,
  billingAddress,
  billingSameAsShipping,
  defaultBillingAddress,
  deliveryAddresses,
  deliveryType,
  forceAddAddress,
  isAllowedBillingAddress,
  isGuest,
  isRemoveTokenDialogOpen,
  paymentTokenToBeRemoved,
  selectedPaymentProvider,
  showAddressBook,
  onCloseAddressBook,
  onConfirmRemoveTokenClick,
  onDeleteAddress,
  onFetchDeliveryAddresses,
  onOpenAddressBook,
  onRemovePaymentToken,
  onRemoveTokenDialogRequestClose,
  onSelectAddress,
  onSelectPaymentMethod,
  onUpdateBillingSameAsShipping,
  onUpdateDeliveryAddress,
}) => {
  const { t } = useTranslation()
  const {
    paymentStyle,
    billingCheckbox,
    boxPayments,
    contentPayment,
    optionsStyle,
    contentOptions,
    textOptions,
    boxInput,
    textCheckbox,
    onSelect,
    isCloseStyle,
    titlePaymentStyle,
    itemOptionsStyle,
    paymentDescription,
    sectionHeader,
    button,
  } = useStyles({ active })
  const selectedPaymentProviderId = _.get(selectedPaymentProvider, 'id')
  const selectedPaymentProviderManualId = _.get(selectedPaymentProvider, 'manualId')
  const [showAddressBookPayment, setShowAddressBookPayment] = useState(false)

  const handleCloseAddressBook = () => {
    setShowAddressBookPayment(false)
  }

  const handleSelectPaymentMethod = (e) => {
    const { value } = e.target
    onSelectPaymentMethod(_.toInteger(value))
  }

  const handleSelectPaymentMethodWithManualId = (e) => {
    const { value } = e.target
    onSelectPaymentMethod(value)
  }

  const handleOpenAddressBook = () => {
    setShowAddressBookPayment(!showAddressBookPayment)
  }

  const handleUpdateBillingSameAsShipping = () => {
    onUpdateBillingSameAsShipping()
  }

  const ImagePayment = ({ children, ...props }) => {
    const classes = useStyles(props)
    return (
      <div className={classes.contentOptions} />
    )
  }

  const handleUpdateDeliveryAddress = (address) => {
    onUpdateDeliveryAddress(address)
    setShowAddressBookPayment(false)
  }

  const renderProvider = (paymentProvider) => (
    <div key={`payment-method-${paymentProvider.id}`} className={itemOptionsStyle}>
      <div className={optionsStyle}>
        <div className={boxInput}>
          <InputRadio
            checked={paymentProvider.id === selectedPaymentProviderId}
            id={paymentProvider.id}
            name="paymentMethod"
            value={paymentProvider.id}
            onChange={handleSelectPaymentMethod}
          />
        </div>
        <ImagePayment iconDataUri={paymentProvider.iconDataUri} />
        {
          _.get(paymentProvider, 'displayMode') !== 'icon_only'
          && (
            <div className={textOptions}>{paymentProvider.name}</div>
          )
        }
      </div>
      {
        !_.isEmpty(paymentProvider.description)
          && paymentProvider.id === selectedPaymentProviderId
          && (
          <div
            className={paymentDescription}
            dangerouslySetInnerHTML={
              { __html: paymentProvider.description }
            }
          />
          )
      }
    </div>

  )

  const renderTokenParent = (paymentProvider) => (
    <div key={`payment-method-${paymentProvider.manualId}`} className={itemOptionsStyle}>
      <div className={optionsStyle}>
        <div className={boxInput}>
          <InputRadio
            checked={paymentProvider.manualId === selectedPaymentProviderManualId}
            id={paymentProvider.manualId}
            name="paymentMethod"
            value={paymentProvider.manualId}
            onChange={handleSelectPaymentMethodWithManualId}
          />
        </div>

        <span className={textOptions}>{paymentProvider.subtitle}</span>
      </div>
      {
        !_.isEmpty(paymentProvider.description)
          && paymentProvider.manualId === selectedPaymentProviderManualId
          && (
          <div
            className={paymentDescription}
            dangerouslySetInnerHTML={
              { __html: paymentProvider.description }
            }
          />
          )
      }
    </div>
  )

  const renderToken = (paymentProvider) => {
    const { paymentToken } = paymentProvider
    const {
      cardType,
      formattedCardNumber,
    } = paymentToken

    return (
      <div key={`payment-method-${paymentProvider.manualId}`} className={itemOptionsStyle}>
        <div className={optionsStyle}>
          <div className={boxInput}>
            <InputRadio
              checked={paymentProvider.manualId === selectedPaymentProviderManualId}
              id={paymentProvider.manualId}
              name="paymentMethod"
              value={paymentProvider.manualId}
              onChange={handleSelectPaymentMethodWithManualId}
            />
          </div>
          <ImagePayment iconDataUri={paymentProvider.iconDataUri} />
          <div>
            <div className={textOptions}>{cardType}</div>
            <div className={textOptions}>{formattedCardNumber}</div>
            <Button
              className={button}
              text={t('screens.checkout.checkout.payment.paymentMethodSection.removePaymentToken')}
              onClick={() => onRemovePaymentToken(paymentProvider)}
            />
          </div>
        </div>
        {
          !_.isEmpty(paymentProvider.description)
            && paymentProvider.manualId === selectedPaymentProviderManualId
            && (
            <div
              className={paymentDescription}
              dangerouslySetInnerHTML={
                { __html: paymentProvider.description }
              }
            />
            )
        }
      </div>
    )
  }

  const renderPayments = () => {
    // If there are payment provider tokens then use section list
    if (_.find(availablePaymentProviders, (m) => m.sectionKey)) {
      const grouped = _.groupBy(availablePaymentProviders, 'sectionKey')
      const sections = _.map(_.keys(grouped), (k) => {
        const data = grouped[k]
        const title = _.get(
          _.find(data, { allowGeneratePaymentToken: true }),
          'name',
          t('screens.checkout.checkout.payment.paymentMethodSection.headers.others'),
        )
        return { data, title }
      })
      return (
        <>
          {
            _.map(sections, (s, sidx) => {
              const { data, title } = s
              return (
                <div key={`payment-section-${sidx}`}>
                  <h3 className={sectionHeader}>{title}</h3>
                  {
                    _.map(data, (paymentProvider) => {
                      if (paymentProvider.sectionKey) {
                        if (paymentProvider.paymentToken) {
                          return renderToken(paymentProvider)
                        }
                        return renderTokenParent(paymentProvider)
                      }
                      return renderProvider(paymentProvider)
                    })
                  }
                </div>
              )
            })
          }
        </>
      )
    }
    // Otherwise fallback to flat list of available payments providers
    return availablePaymentProviders.map((paymentProvider) => (
      <div key={`payment-method-${paymentProvider.id}`} className={itemOptionsStyle}>
        <div className={optionsStyle}>
          <div className={boxInput}>
            <InputRadio
              checked={paymentProvider.id === selectedPaymentProviderId}
              id={paymentProvider.id}
              name="paymentMethod"
              value={paymentProvider.id}
              onChange={handleSelectPaymentMethod}
            />
          </div>
          <ImagePayment iconDataUri={paymentProvider.iconDataUri} />
          {
            _.get(paymentProvider, 'displayMode') !== 'icon_only'
            && (
              <span className={textOptions}>{paymentProvider.name}</span>
            )
          }
        </div>
        {
          !_.isEmpty(paymentProvider.description)
            && paymentProvider.id === selectedPaymentProviderId
            && (
            <div
              className={paymentDescription}
              dangerouslySetInnerHTML={
                { __html: paymentProvider.description }
              }
            />
            )
        }
      </div>
    ))
  }

  return (
    <div className={combineClassNames([paymentStyle, !active && isCloseStyle])}>
      <Title
        isActive={active}
        text={t('screens.checkout.checkout.payment.title')}
        isEditable={false}
        buttonText={t('screens.checkout.checkout.buttons.edit')}
        onEdit={handleOpenAddressBook}
      />
      {
        active
          ? (
            <form className={contentPayment}>
              <div className={boxPayments}>
                {
                  renderPayments()
                }
              </div>
              {

                isAllowedBillingAddress && (
                  <>
                    {
                      !forceAddAddress && (
                        <div>
                          <Checkbox
                            wrapperStyle={billingCheckbox}
                            checked={billingSameAsShipping}
                            onChange={handleUpdateBillingSameAsShipping}
                          >
                            <p className={textCheckbox}>{t('screens.checkout.checkout.payment.billing', { context: _.camelCase(deliveryType) })}</p>
                          </Checkbox>
                        </div>
                      )
                    }
                    {
                      (!billingSameAsShipping && (showAddressBookPayment || _.isEmpty(billingAddress))) && (
                        <AddressManager
                          addresses={deliveryAddresses}
                          addressSelectable
                          addressType="billing"
                          autoSelectPrimaryAddress
                          closeAfterSelectAddress
                          defaultAddress={defaultBillingAddress}
                          disableAddressBook={isGuest}
                          selectedAddress={billingAddress}
                          onAddress
                          onClose={handleCloseAddressBook}
                          onDeleteAddress={onDeleteAddress}
                          onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                          onSelectAddress={onSelectAddress}
                          onUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                        />
                      )
                    }
                    {
                      (!billingSameAsShipping && !showAddressBookPayment) && (
                        <Info
                          t={t}
                          isAddress
                          address={billingAddress}
                          title={t('screens.checkout.checkout.payment.subtitle')}
                          onOpenAddressBook={handleOpenAddressBook}
                          canOpenAddressBook
                        />
                      )
                    }
                  </>
                )
              }
            </form>
          )
          : ''
        }
      <PaymentProviderTokenDeleteModal
        isOpen={isRemoveTokenDialogOpen}
        detail={`${_.get(paymentTokenToBeRemoved, 'paymentToken.cardType')} ${_.get(paymentTokenToBeRemoved, 'paymentToken.formattedCardNumber')}`}
        onRequestClose={onRemoveTokenDialogRequestClose}
        onConfirm={onConfirmRemoveTokenClick}
      />
    </div>
  )
}

export default PaymentView
