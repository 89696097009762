/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './delivery-style'
import Title from '../title/title-view'
import {
  Shipping,
} from './components'
import Info from '../info/info-view'
import { AddressManager } from '../../../../ui'
import getStoreInfoFromDescription from '../../../../helpers/get-store-info-from-description'
import getCartValidationErrors from '../../../../helpers/get-cart-validation-errors'

const DeliveryView = ({
  isolateCart,
  availableCourierServices,
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  defaultDeliveryAddress,
  deliveryAddresses,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  isGuest,
  shipment,
  shipmentDeliveryAddress,
  showAddressBook,
  showAddressForm,
  submitDisabled,
  onConfirmDelivery,
  onDeleteAddress,
  onFetchDeliveryAddresses,
  showDeliveryDateTimeOptions,
  showNoAvailableCourierServiceMessage,
  showNoAvailableDeliveryDateMessage,
  showNoAvailableDeliveryTimeSlotsMessage,
  onCloseAddressBook,
  onOpenAddressBook,
  onSelectAddress,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  onSelectCourierService,
  onUpdateDeliveryAddress,
  onOpenDeliveryDateTimeOptions,
  requireDeliveryAddress,
  requireCourierService,
  requirePickupStore,
}) => {
  // prepare hook
  const { t } = useTranslation()

  // style
  const styles = useStyles()

  const isCatering = !requireDeliveryAddress && requireCourierService && requirePickupStore

  // Handle Address from Description
  // get pickup store info if available
  let infoAddress = null
  const store = _.get(shipment, 'pickupStore', {})
  if (isCatering && store) {
    const { address = {}, description = '' } = store
    const {
      formattedAddressLines = [],
      ...otherAddressProps
    } = address
    const descriptionHtml = (description || '').trim()
    const descriptionAddressLines = _.map(getStoreInfoFromDescription('address', descriptionHtml), 'content')
    const addressLines = _.isEmpty(descriptionAddressLines)
      ? formattedAddressLines
      : descriptionAddressLines
    infoAddress = {
      formattedAddressLines: addressLines,
      ...otherAddressProps,
    }
  }

  const validationErrors = _.get(isolateCart, 'validationErrors', [])
  const errorKey = _.first(getCartValidationErrors(validationErrors, { type: 'shipment' }))

  const shippingViewProps = {
    availableCourierServices,
    availableDeliveryDate,
    availableDeliveryTimeSlots,
    deliveryDateUsable,
    deliveryTimeSlotUsable,
    isolateCart,
    showNoAvailableCourierServiceMessage,
    showNoAvailableDeliveryDateMessage,
    showNoAvailableDeliveryTimeSlotsMessage,
    submitDisabled,
    onConfirmDelivery,
    onSelectCourierService,
    onSelectDeliveryDate,
    onSelectDeliveryTimeSlot,
    requireDeliveryAddress,
    requireCourierService,
  }

  return (
    <div className={styles.container}>
      <Title
        isActive
        isEditable={!showDeliveryDateTimeOptions}
        t={t}
        text={isCatering ? t('screens.checkout.checkout.catering.title') : t('screens.checkout.checkout.delivery.title')}
        buttonText={t('screens.checkout.checkout.buttons.edit')}
        onEdit={onOpenDeliveryDateTimeOptions}
      />
      {
        requireDeliveryAddress && (showAddressBook || showAddressForm)
          ? (
            <AddressManager
              addresses={deliveryAddresses}
              addressSelectable
              addressType="delivery"
              autoSelectPrimaryAddress
              closeAfterSelectAddress
              defaultAddress={defaultDeliveryAddress}
              disableAddressBook={isGuest}
              formFieldsLabelOverride={{
                streetLine1: { translationKey: 'screens.checkout.checkout.address.form.streetLine1' },
                streetLine2: { translationKey: 'screens.checkout.checkout.address.form.streetLine2' },
                city: { translationKey: 'screens.checkout.checkout.address.form.city' },
                country: { translationKey: 'screens.checkout.checkout.address.form.country' },
                district: { translationKey: 'screens.checkout.checkout.address.form.district' },
                zip: { translationKey: 'screens.checkout.checkout.address.form.zip' },
              }}
              selectedAddress={shipmentDeliveryAddress}
              onAddress
              onClose={onCloseAddressBook}
              onDeleteAddress={onDeleteAddress}
              onFetchDeliveryAddresses={onFetchDeliveryAddresses}
              onSelectAddress={onSelectAddress}
              onUpdateDeliveryAddress={onUpdateDeliveryAddress}
            />
          )
          : (
            <>
              { isCatering
                ? (
                  <Info
                    t={t}
                    isAddress
                    isHtmlAddress
                    address={infoAddress}
                    title={t('screens.checkout.checkout.collection.subtitle')}
                  />
                ) : (
                  <Info
                    t={t}
                    isAddress
                    address={shipmentDeliveryAddress}
                    title={t('screens.checkout.checkout.delivery.subtitle')}
                    onOpenAddressBook={onOpenAddressBook}
                    canOpenAddressBook={showDeliveryDateTimeOptions}
                  />
                ) }
              {
                showDeliveryDateTimeOptions
                  ? (
                    <Shipping {...shippingViewProps} />
                  )
                  : (
                    <Info
                      t={t}
                      shipment={shipment}
                      address={shipmentDeliveryAddress}
                      title={t('screens.checkout.checkout.shipping.title')}
                    />
                  )
              }
              {
                // courier service errors
                !_.isEmpty(errorKey) && (
                  <p className={styles.errorMessage}>
                    {t('screens.checkout.checkout.shipping.errors', { context: errorKey })}
                  </p>
                )
              }
            </>
          )
      }
    </div>
  )
}

export default DeliveryView
