import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  summaryStyle: {
    marginTop: '7.4rem',
    marginBottom: '17rem',
  },
  titleStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '3.4rem',
    '& h4': {
      fontSize: '1.8rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      margin: 0,
    },
  },
  buttonStyle: {
    width: '5rem',
    padding: 0,
    margin: 0,
    fontSize: '1.4rem',
  },
  boxProducts: {
    marginBottom: '2rem',
  },
  summaryCheckout: {
    border: 0,
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    summaryStyle: {
      marginTop: 0,
      marginBottom: 0,
    },
    titleStyle: {
      alignItems: 'flex-start',
    },
    buttonStyle: {
      maxHeight: '2.5rem',
      minHeight: '2.5rem',
    },
  },
}))

export default useStyles
