import _ from 'lodash'
import React, { useEffect } from 'react'
import { useOrders } from 'react-omnitech-api'
import PaymentQrCodeModalView from './payment-qr-code-modal-view'
import { poll } from '../../../../helpers';

export default function PaymentQrCodeModalController({
  orderUuid: uuid = '',
  paymentType = '',
  qrCode,
  isOpen = false,
  onRequestClose,
  onPaymentSuccess,
  onPaymentError,
}) {
  const { fetchOrderByUuid } = useOrders()

  // poll order and check status for order complete
  useEffect(() => {
    if (!_.isEmpty(uuid) && isOpen) {
      poll(
        () => fetchOrder(),
        60000 * 5,
        1000,
        (resp) => (!resp.retry || !isOpen),
      )
        .then((order) => {
          // payment success, reset and create new cart and go to checkout complete page
          onPaymentSuccess(order)
        })
        .catch((err) => {
          console.warn(err)
          const paymentRequests = _.get(err, 'paymentRequests')
          let error = {}
          if (!_.isEmpty(paymentRequests)) {
            const { errorMessages = [] } = _.last(paymentRequests)
            error = {
              ...err,
              generalError: {
                message: _.join(errorMessages, '\n'),
              },
            }
          } else {
            error = err
          }
          onPaymentError(error)
        })
    }
  }, [uuid, isOpen])

  async function fetchOrder() {
    try {
      const includes = [
        'payment_requests',
      ]
      const apiParams = {
        uuid,
        includes,
        schema_version: '2019-08-15',
        price_template: 'mobile_v1',
        arrayFormat: 'bracket',
      }
      const { order } = await fetchOrderByUuid(apiParams)
      const { paymentRequests = [], state } = order
      const paymentRequest = _.last(paymentRequests) || {}

      if (state === 'reserved') {
        return order
      }
      switch (paymentRequest.state) {
        case 'hook_received':
          return { ...order, retry: true }
        case 'authorized':
        case 'completed':
          return order
        default:
          throw order
      }
    } catch (error) {
      console.warn('handleGetOrders error: ', error)
      throw error
    }
  }

  const viewProps = {
    isOpen,
    paymentType,
    qrCode,
    onRequestClose,
  }

  return (
    <PaymentQrCodeModalView {...viewProps} />
  )
}
