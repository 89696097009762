/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import Modal from '../../../../ui/modal';
import Cross from '../../../../assets/icons/icon_cross.svg'
import HsbcPaymeLogo from '../../../../assets/icons/icon-hsbc-payme-full.svg'
import HsbcPayMePayCode from '../../../../vendor/hsbc-payme-paycode-generator/react-paycode'
import {
  useThemeConfig,
} from '../../../../hook/use-theme-config'
import useStyles from './payment-qr-code-modal-style'

export default function PaymentQrCodeModalView({
  isOpen,
  paymentType = 'wechat',
  qrCode,
  onRequestClose,
}) {
  const { getConfig } = useThemeConfig()
  const { t } = useTranslation()
  const styles = useStyles()

  const qrcodeProps = {
    bgColor: '#ffffff',
    className: 'qrcode',
    fgColor: '#000000',
    level: 'M',
    size: 200,
    value: qrCode,
  }

  const headerLogosMapping = {
    hsbcPayme: HsbcPaymeLogo,
  }
  const qrCodeIsImg = qrCode && _.startsWith(qrCode, 'data:image')
  const headerLogo = _.get(headerLogosMapping, paymentType)
  const logoOnQrCode = getConfig('config.pages.checkout.paymentQrCodeModal.onQrCodeLogo', getConfig('config.logoImageMobile', ''))
  const instructionsHtml = t(
    'screens.checkout.checkout.paymentQrCodeModal.instructionsHtml',
    {
      context: paymentType,
      defaultValue: '',
    },
  )
  const renderQrCode = () => {
    if (!qrCode) return null
    if (qrCodeIsImg) return <img className={styles.qrcodeImage} src={qrCode} alt={`${paymentType}_qr_code`} />
    if (paymentType === 'hsbcPayme') {
      return (
        <HsbcPayMePayCode
          {...qrcodeProps}
          logoSrc={logoOnQrCode}
          // HSBC recommended the minimum size for PayCode is 250px by 250px
          size={_.max([qrcodeProps.size, 250])}
        />
      )
    }
    return <QRCode {...qrcodeProps} />
  }

  return (
    <Modal
      className={styles.qrCodeModal}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        t('screens.checkout.checkout.paymentQrCodeModal.title', { context: paymentType })
      }
      titleClassName={styles.title}
    >
      <div
        className={styles.closeButton}
        onClick={onRequestClose}
      >
        <img src={Cross} alt="" />
      </div>
      {
        headerLogo && (
          <img
            className={styles.headerLogo}
            src={headerLogo}
            alt={
              t(
                'screens.checkout.checkout.paymentQrCodeModal.headerLogoAlt',
                {
                  context: paymentType,
                  defaultValue: '',
                },
              )
            }
          />
        )
      }
      <div className={styles.content}>
        <div className={styles.qrcodeContainer}>
          {renderQrCode()}
        </div>
        <div className={styles.messageContainer}>
          <p className={styles.messageText}>
            {!_.isEmpty(paymentType) && t(`screens.checkout.checkout.paymentQrCodeModal.message.${paymentType}`)}
          </p>
        </div>
        {
          !_.isEmpty(instructionsHtml) && (
            <div
              className={styles.instructions}
              dangerouslySetInnerHTML={{
                __html: instructionsHtml,
              }}
            />
          )
        }
      </div>
    </Modal>
  )
}
