import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid currentColor',
    width: '100%',
    textAlign: 'left',
    color: theme.colors.disabled,
  },
  containerActive: {
    color: theme.colors.text,
  },
  button: {
    width: '5rem',
    marginBottom: '0.8rem',
    fontSize: '1.2rem',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 600,
    marginBottom: '0.8rem',
  },
}))

export default useStyles
