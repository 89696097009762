/* eslint-disable no-param-reassign */
import React, { useMemo, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import { generateTimeslots } from '../../../../helpers'
import { useLink } from '../../../../hook'
import CollectionView from './collection-view'

const CollectionController = ({
  isolateCart,
  progress,
  onConfirmCollection,
  onSelectCollectionTime,
}) => {
  // prepare hook
  const { navigate } = useLink()
  const { getSystemSetting } = useSystemSettings()
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  // internal state
  // TODO: need to support selecting pickup date and timeslot after
  // API implemented proper version for collect at store shipment
  // const [availablePickupDate, setAvailablePickupDate] = useState([])
  // const [availablePickupTimeSlot, setAvailablePickupTimeSlot] = useState([])
  const [selectedPickupDate, setSelectedPickupDate] = useState()
  const [selectedPickupTime, setSelectedPickupTime] = useState()
  const [confirmed, setConfirmed] = useState(false)

  // local variable
  const store = _.get(isolateCart, 'cartShipments[0].pickupStore', {})
  const todayOpentimeSlots = _.get(store, 'todayOpenTimeSlots', [])
  const takeAwayTimePeriod = _.get(store, 'meta.takeAwayTimePeriod', '30m')
  const takeAwayTimeEnableImmediateOption = _.get(
    store,
    'meta.takeAwayTimeEnableImmediateOption',
    false,
  )
  const takeAwayPreparationTime = _.toInteger(_.trimEnd(_.get(store, 'meta.takeAwayBufferTime', '30m'), 'm'))
  const isStoreClosed = _.isEmpty(
    _.find(todayOpentimeSlots, ({ start, end }) => (moment().isBetween(start, end))),
  )

  // useMemo
  const active = useMemo(() => (
    progress.indexOf('collect_at_store') !== -1
  ), [progress])

  const availablePickupTimeSlot = useMemo(() => {
    if (fnbEnabled && !isStoreClosed) {
      const outerResult = _.reduce(todayOpentimeSlots, (result, { start, end }) => {
        result = _.concat(
          result,
          generateTimeslots({
            start: moment(moment().diff(start, 'minutes') > 0 ? moment() : start).add(takeAwayPreparationTime, 'minutes'),
            end: moment(end).add(takeAwayPreparationTime, 'minutes'),
            interval: _.toInteger(_.trimEnd(takeAwayTimePeriod, 'm')),
          }),
        )
        return result
      }, [])
      // add transition to first time slot
      if (!_.isEmpty(outerResult) && takeAwayTimeEnableImmediateOption) {
        outerResult.unshift(moment().format('HH:mm'))
      }
      return outerResult
    }
    return []
  }, [fnbEnabled, isStoreClosed, takeAwayPreparationTime, takeAwayTimePeriod, todayOpentimeSlots])

  const collectionTime = useMemo(() => {
    if (_.isEmpty(selectedPickupDate) || _.isEmpty(selectedPickupTime)) return
    return moment(`${selectedPickupDate} ${selectedPickupTime}`).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
  }, [selectedPickupDate, selectedPickupTime])

  function handleChangeStore() {
    navigate('/cart/')
  }

  function onCollectionTimeChange({ value }) {
    setSelectedPickupTime(value)
  }

  function onConfirm() {
    setConfirmed(true)
    if (!_.isFunction(onConfirmCollection)) return
    onConfirmCollection(collectionTime)
  }

  useEffect(() => {
    if (!fnbEnabled) {
      // before pickup date and timeslot is supported, skip and continue to next step
      onConfirmCollection()
      return
    }
    // FL: Auto select today as pickup date for Fnb
    setSelectedPickupDate(moment().format('YYYY-MM-DD'))
  }, [fnbEnabled])

  useEffect(() => {
    if (_.isEmpty(collectionTime)) return
    if (!_.isFunction(onSelectCollectionTime)) return
    onSelectCollectionTime(collectionTime)
  }, [collectionTime])

  const viewProps = {
    active,
    // availablePickupDate,
    availablePickupTimeSlot,
    collectionTime,
    confirmed,
    fnbEnabled,
    selectedPickupTime,
    store,
    takeAwayTimeEnableImmediateOption,
    onChangeStore: handleChangeStore,
    onCollectionTimeChange,
    onConfirm,
  }

  return (
    <CollectionView {...viewProps} />
  )
}

export default CollectionController
