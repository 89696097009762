/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useUser, useSystemSettings, usePayment } from 'react-omnitech-api'
import { isBrowser } from '../../../../helpers'
import * as detectBrowser from '../../../../helpers/browser'
import PaymentView from './payment-view'

const PaymentController = ({
  billingSameAsShipping,
  deliveryAddresses,
  deliveryType,
  isolateCart = {},
  progress,
  selectedPaymentProvider,
  onDeleteAddress,
  onError,
  onFetchDeliveryAddresses,
  onSelectBillingAddress,
  onSelectPaymentMethod,
  onSetLoading,
  onUpdateBillingSameAsShipping,
  onUpdateDeliveryAddress,
}) => {
  // prepare hook
  const { t } = useTranslation()
  const { user } = useUser()
  const { getSystemSetting } = useSystemSettings()
  const { deletePaymentProviderToken } = usePayment()
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  // TODO: if billing address is not set, pre-select billing is same as shipping
  // TODO: if only one payment provider is available, pre-select the payment provider
  const [showAddressBook, setShowAddressBook] = useState(false)
  const [forceAddAddress, setForceAddAddress] = useState(false)
  const [isRemoveTokenDialogOpen, setIsRemoveTokenDialogOpen] = useState(false)
  const [paymentTokenToBeRemoved, setPaymentTokenToBeRemoved] = useState(null)
  const [removedTokenProviders, setRemovedTokenProviders] = useState([])

  // local variable
  const { billingAddress = {} } = isolateCart
  const cartShipment = _.get(isolateCart, 'cartShipments[0]', {})
  const defaultBillingAddress = _.find(deliveryAddresses, { isBilling: true })
  const deliveryAddressId = _.get(cartShipment, 'deliveryAddressId')
  const isGuest = _.isEmpty(user)
  const active = useMemo(() => (
    progress.indexOf('payment_method') !== -1
  ), [progress])

  const isAllowedBillingAddress = /undefined|null|true/.test(getSystemSetting('order.checkout.billing_address.enable.ecom'))

  const isNeedBillingAddress = /undefined|null|true/.test(getSystemSetting('order.checkout.billing_address.fallback_to_delivery_address_when_disable.ecom'))

  const availablePaymentProviders = useMemo(() => {
    // skip when is it not in browser, prevent get error in build stage
    if (!isBrowser()) return []

    const supportedPaymentMethodTypes = [
      'PAY2GO___pay2go',
      'NEWEBPAY___newebpay',
      'NEWEBPAY___newebpay_cvsb2c',
      'CARD_DEMO___DEMO_CARD',
      'CYBERSOURCE___CYBS_VM',
      'CYBERSOURCE___CYBS_VM_3DS',
      'CYBERSOURCE___CYBS_VM_TOKEN',
      'CYBERSOURCE___CYBS_VM_TA',
      'CYBERSOURCE_APPLEPAY___CYBS_APPLEPAY_WEB',
      'CYBERSOURCE_APPLEPAY___CYBS_APPLEPAY_TA',
      'PAYME___PAYMENT_REQUEST',
      'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYHK',
      'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYCN',
      'QFPAY_OVERSEAS___WECHAT_H5_WEB_MAINLAND_HK',
      'QFPAY_OVERSEAS___QR_PAY_WECHAT_MAINLAND_HK',
      'QFPAY_OVERSEAS___QR_PAY_FPS',
      'QFPAY_OVERSEAS___QR_PAY_PAYME_WEB',
      'QFPAY_OVERSEAS___QR_PAY_UNIONPAY_QUICKPASS',
      'QFPAY_OVERSEAS___ONLINE_CHECKOUT',
      'COD___cod',
      'CUSTOMER_MANUAL___BANK_TRANSFER',
      'STRIPE___STRIPE',
    ]

    const paymentProviders = _.get(isolateCart, 'availablePaymentProviders', [])
    let sortedPaymentProviders = _.filter(paymentProviders, (provider) => {
      const {
        paymentProviderInternalCode,
        paymentGatewayServiceCode,
      } = provider
      const paymentMethodType = `${_.toUpper(paymentGatewayServiceCode)}___${paymentProviderInternalCode}`
      return _.includes(supportedPaymentMethodTypes, paymentMethodType)
    })

    // when inside Alipay in app browser and Alipay is available, only show alipay
    if (detectBrowser.isAlipayBrowser()
      && (_.includes(supportedPaymentMethodTypes, 'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYHK')
        || _.includes(supportedPaymentMethodTypes, 'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYCN'))
    ) {
      return _.filter(sortedPaymentProviders,
        (p) => [
          'ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYHK',
          'ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYCN',
        ].includes(p.paymentProviderInternalCode))
    }

    // when inside WeChat in app browser and WeChat Pay is available, only show WeChat Pay
    if (detectBrowser.isWeChatBrowser()
      && (_.includes(supportedPaymentMethodTypes, 'QFPAY_OVERSEAS___WECHAT_H5_WEB_MAINLAND_HK')
        || _.includes(supportedPaymentMethodTypes, 'QFPAY_OVERSEAS___WECHAT_H5_MAINLAND_HK'))
    ) {
      return _.filter(sortedPaymentProviders,
        (p) => ['WECHAT_H5_MAINLAND_HK', 'WECHAT_H5_WEB_MAINLAND_HK'].includes(p.paymentProviderInternalCode))
    }

    sortedPaymentProviders = _.reduce(
      sortedPaymentProviders,
      (result, provider) => {
        const { paymentProviderInternalCode } = provider

        switch (paymentProviderInternalCode) {
          case 'ALIPAY_H5_WAP_MAINLAND_HK':
            // only include alipay if not in wechat in app browser
            if (!detectBrowser.isWeChatBrowser() && detectBrowser.isMobileBrowser()) {
              result.push(provider)
            }
            break
          // when apple pay is not availabe in the browser, does not show it
          case 'CYBS_APPLEPAY_WEB':
          case 'CYBS_APPLEPAY_TA':
            if (window.ApplePaySession) {
              result.push(provider)
            }
            break
          // only include qr pay in desktop browser
          case 'QR_PAY_WECHAT_MAINLAND_HK':
          case 'QR_PAY_FPS':
          case 'QR_PAY_UNIONPAY_QUICKPASS':
            if (!detectBrowser.isMobileBrowser()) {
              result.push(provider)
            }
            break
          case 'WECHAT_H5_MAINLAND_HK':
          case 'WECHAT_H5_WEB_MAINLAND_HK':
            // only include wechat pay if not in alipay in app browser
            if (!detectBrowser.isAlipayBrowser() && detectBrowser.isMobileBrowser()) {
              result.push(provider)
            }
            break
          // 20200525: when selected alipay or wechat, will show a qrcode of checkout page url
          // user will need to scan and continue in in app browser. So does not need to filter out
          // Alipay and WeChat in corresponding in app browsers.
          default:
            result.push(provider)
            break
        }
        return result
      },
      [],
    )

    const parent = _.find(sortedPaymentProviders,
      (p) => p.paymentProviderInternalCode === 'CYBS_VM' && p.allowGeneratePaymentToken)
    const child = _.find(sortedPaymentProviders, { paymentProviderInternalCode: 'CYBS_VM_TOKEN' })
    if (parent && child) {
      _.remove(sortedPaymentProviders, (p) => p.id === parent.id || p.id === child.id)
      const parentProviders = [{
        ...parent,
        sectionKey: 'cybs_vm',
        extraParams: { generatePaymentToken: true },
        subtitle: t('screens.checkout.checkout.payment.paymentMethodSection.names.add'),
        manualId: `${parent.id}-add`,
      }, {
        ...parent,
        sectionKey: 'cybs_vm',
        subtitle: t('screens.checkout.checkout.payment.paymentMethodSection.names.oneTime'),
        manualId: `${parent.id}-oneTime`,
      }]
      const { availablePaymentProviderTokens } = child
      _.remove(availablePaymentProviderTokens, (paymentToken) => (
        _.some(removedTokenProviders, (p) => {
          const { paymentToken: pt } = p
          return (p.id === child.id && pt.id === paymentToken.id)
        })
      ))
      const childProviders = _.map(availablePaymentProviderTokens, (paymentToken) => ({
        ...child,
        sectionKey: 'cybs_vm',
        paymentToken: {
          ...paymentToken,
          formattedCardNumber: _.replace(paymentToken.displayCardNumber, /x+/, '****'),
        },
        extraParams: {
          paymentProviderTokenId: paymentToken.id,
        },
        manualId: `${child.id}-${paymentToken.id}`,
      }))
      sortedPaymentProviders.unshift(...parentProviders)
      sortedPaymentProviders.unshift(...childProviders)
    }
    return sortedPaymentProviders
  }, [isolateCart, removedTokenProviders])

  function handleSelectPaymentMethod(paymentProviderId) {
    const paymentProvider = _.find(availablePaymentProviders, { manualId: paymentProviderId })
      || _.find(availablePaymentProviders, { id: paymentProviderId })
    onSelectPaymentMethod(paymentProvider)
  }

  function handleCloseAddressBook() {
    setShowAddressBook(false)
  }

  function handleOpenAddressBook() {
    setShowAddressBook(true)
  }

  async function handleSelectAddress(address) {
    try {
      onSetLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      await onSelectBillingAddress(address.id)
      setShowAddressBook(false)
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  function handleRemovePaymentToken(p) {
    setPaymentTokenToBeRemoved(p)
    setIsRemoveTokenDialogOpen(true)
  }

  function handleRemoveTokenDialogRequestClose() {
    setIsRemoveTokenDialogOpen(false)
  }

  async function handleConfirmRemoveTokenClick() {
    try {
      onSetLoading(true)
      setIsRemoveTokenDialogOpen(false)
      const { paymentToken } = paymentTokenToBeRemoved
      await deletePaymentProviderToken({ paymentProviderTokenId: paymentToken.id })
      setRemovedTokenProviders([...removedTokenProviders, paymentTokenToBeRemoved])
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  function selectDefaultBillingAddress() {
    const { deliveryAddress, deliveryType: shipmentDeliveryType } = cartShipment
    if (_.get(shipmentDeliveryType, 'requireDeliveryAddress', false) && !_.isEmpty(deliveryAddress)) {
      onSelectBillingAddress(deliveryAddress.id)
      return
    }

    if (_.isEmpty(deliveryAddresses)) {
      onUpdateBillingSameAsShipping()
      setShowAddressBook(true)
      setForceAddAddress(true)
      return
    }

    if (shipmentDeliveryType === 'collect_at_store') {
      const defaultAddress = _.find(deliveryAddresses, { isPrimary: true })
      onSelectBillingAddress(defaultAddress.id)
    }
  }

  useEffect(() => {
    if (_.size(availablePaymentProviders) === 1) {
      const provider = _.first(availablePaymentProviders)
      handleSelectPaymentMethod(provider.id)
    }
  }, [])

  useEffect(() => {
    if (_.isEmpty(billingAddress)) {
      selectDefaultBillingAddress()
    }
  }, [])

  useEffect(() => {
    if (!isNeedBillingAddress) return
    if (billingSameAsShipping) {
      if (_.isEmpty(deliveryAddressId)) {
        selectDefaultBillingAddress()
      } else {
        onSelectBillingAddress(deliveryAddressId)
      }
    } else {
      setShowAddressBook(true)
    }
  }, [deliveryAddressId, isNeedBillingAddress, billingSameAsShipping])

  useEffect(() => {
    if (_.isEmpty(deliveryAddresses)) return

    if (forceAddAddress) {
      setForceAddAddress(false)
    }
  }, [deliveryAddresses, forceAddAddress])

  const viewProps = {
    active,
    availablePaymentProviders,
    billingAddress,
    billingSameAsShipping,
    defaultBillingAddress,
    deliveryAddresses,
    deliveryType,
    fnbEnabled,
    forceAddAddress,
    isGuest,
    isAllowedBillingAddress,
    isRemoveTokenDialogOpen,
    paymentTokenToBeRemoved,
    selectedPaymentProvider,
    showAddressBook,
    onCloseAddressBook: handleCloseAddressBook,
    onConfirmRemoveTokenClick: handleConfirmRemoveTokenClick,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onOpenAddressBook: handleOpenAddressBook,
    onRemovePaymentToken: handleRemovePaymentToken,
    onRemoveTokenDialogRequestClose: handleRemoveTokenDialogRequestClose,
    onSelectAddress: handleSelectAddress,
    onSelectBillingAddress,
    onSelectPaymentMethod: handleSelectPaymentMethod,
    onUpdateBillingSameAsShipping,
    onUpdateDeliveryAddress,
  }

  return (
    <PaymentView {...viewProps} />
  )
}

export default PaymentController
