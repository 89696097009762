import qrcode from './qrcode'
import { drawPayCode } from './paycode'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

const PayCode = forwardRef(function PayCode(props, forwardedRef) {
  const {
    bgColor,
    canvasStyle,
    consumer = false,
    fgColor,
    margin = 7,
    level = 'M',
    logoSrc,
    size,
    value,
    ...otherProps
  } = props

  const _canvas = useRef(null)
  const logoImageRef = useRef(new Image())
  const [logoLoaded, setLogoLoaded] = useState(false)

  const createQrcode = function (text, typeNumber, errorCorrectionLevel, mode, mb) {
    qrcode.stringToBytes = qrcode.stringToBytesFuncs[mb];
    if (typeNumber == 0) {
      typeNumber = suggestTypeNumber(text);
    }

    const qr = qrcode(typeNumber || 4, errorCorrectionLevel || 'M');
    qr.addData(text, mode);
    qr.make();

    return qr
  };

  const suggestTypeNumber = function (text) {
    let length = text.length;
    if (length <= 32) { return 3; }
    else if (length <= 46) { return 4; }
    else if (length <= 60) { return 5; }
    else if (length <= 74) { return 6; }
    else if (length <= 86) { return 7; }
    else if (length <= 108) { return 8; }
    else if (length <= 130) { return 9; }
    else if (length <= 151) { return 10; }
    else if (length <= 177) { return 11; }
    else if (length <= 203) { return 12; }
    else if (length <= 241) { return 13; }
    else if (length <= 258) { return 14; }
    else if (length <= 292) { return 15; }
    else { return 40; }
  }

  // Set the local ref (_canvas) and also the forwarded ref from outside
  const setCanvasRef = useCallback(
    (node) => {
      _canvas.current = node;
      if (typeof forwardedRef === 'function') {
        forwardedRef(node);
      } else if (forwardedRef) {
        forwardedRef.current = node;
      }
    },
    [forwardedRef]
  );

  useEffect(() => {
    const logo = logoImageRef.current
    logo.src = logoSrc
    logo.onload = () => {
      // trigger render qrcode when logo loaded
      setLogoLoaded(true)
    }
  }, [logoSrc])

  useEffect(() => {
    if (_canvas.current != null) {
      const canvas = _canvas.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      let qrcode = createQrcode(value, 0, level, 'Byte', 'UTF-8')
      const pixelRatio = window.devicePixelRatio || 1;
      canvas.height = canvas.width = size * pixelRatio;
      // const scale = (size / numCells) * pixelRatio;
      // ctx.scale(scale, scale);

      ctx.fillStyle = bgColor;
      ctx.fillStyle = fgColor;
      const logo = logoImageRef.current
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawPayCode(qrcode, canvas, margin, logo, consumer);
    }
  })

  return (
    <canvas
      style={canvasStyle}
      height={size}
      width={size}
      ref={setCanvasRef}
      role="img"
      {...otherProps}
    />
  );
})

export default PayCode
