import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  contentNotice: {
    textAlign: 'left',
    '& h4': {
      textTransform: 'uppercase',
      fontSize: '1.6rem',
      lineHeight: 1.18,
      fontWeight: 600,
      marginTop: '7.2rem',
    },
    '& p': {
      marginBottom: '4.4rem',
      fontSize: '1.4rem',
      lineHeight: 1.43,
      fontWeight: 400,
    },
    '& button': {
      width: '100%',
      padding: '1.2rem',

    },
  },
  title: {
    fontSize: 12,
    lineHeight: '2rem',
    color: theme.colors.primary,
  },
  containerLogos: {
    padding: '4.6rem 0 0',
  },
  itemIconStyle: {
    '& img': {
      width: '7.5rem',
      marginRight: 0,
      height: '3.5rem',
    },
    '&:first-child img': {
      width: '4rem',
      marginRight: 24,
      height: '4rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    contentNotice: {
      '& button': {
        width: '37rem',
      },
    },
  },
  dialogContainer: {
    maxWidth: '57rem',
    margin: '0 auto',
    padding: '0.8rem 0',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.6rem',
    },
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },
  agreeTerms: {
    marginTop: '3rem',
    marginBottom: '3rem',
    maxHeight: 'none',
  },
  inlineLink: {
    color: theme.colors.inlineLink,
  },
}))

export default useStyles
