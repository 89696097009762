/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import useStyles from './title-style'
import Button from '../../../../ui/button'
import combineClassNames from '../../../../helpers/combineClassNames'

const TitleView = ({
  text,
  isActive,
  buttonText,
  isEditable,
  onEdit,
}) => {
  const styles = useStyles()

  return (
    <div
      className={
        combineClassNames([styles.container, isActive && styles.containerActive])
      }
    >
      <p
        className={combineClassNames([styles.title])}
      >
        {text}
      </p>
      {
        isEditable && (
          <Button
            inline
            className={combineClassNames([styles.button])}
            text={buttonText}
            onClick={onEdit}
          />
        )
      }
    </div>
  )
}

export default TitleView
