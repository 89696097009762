/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './summary-style'
import { Button, ProductMiniCart, OrderSummary } from '../../../../ui'

const SummaryView = ({
  cartLines = [],
  summary,
  onEditCart,
}) => {
  const { t } = useTranslation()

  const style = useStyles()

  return (
    <div className={style.summaryStyle}>
      <div className={style.titleStyle}>
        <h4>{t('screens.checkout.checkout.orderSummary.title')}</h4>
        <Button
          inline
          className={style.buttonStyle}
          text={t('screens.checkout.checkout.orderSummary.button')}
          onClick={onEditCart}
        />
      </div>

      <div className={style.boxProducts}>
        {
          cartLines.map((cartLine) => (
            <ProductMiniCart key={cartLine.id} cartLine={cartLine} />
          ))
        }
      </div>
      <OrderSummary
        classN={style.summaryCheckout}
        summary={summary}
      />
    </div>
  )
}

export default SummaryView
